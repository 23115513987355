import React from 'react';
import style from './NotFound.style';
import { useTranslation } from 'react-i18next';


const NotFound = (props) => {
    const { t, } = useTranslation('translation', { useSuspense: false });

    return (
        <div className="container">
            <div className={style.notfound}>
                <img src="./images/404-img.svg" alt={'404 Page not found'} className={style.notfound__img}/>
                <p className={style.notfound__text}>{t('error_page_info_text')}</p>
            </div>
        </div>
    );
};

export default NotFound;