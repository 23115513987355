import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './locales/en/localization.json';
import de from './locales/de/localization.json';
import es from './locales/es/localization.json';
import it from './locales/it/localization.json';
import ja from './locales/ja/localization.json';
import ko from './locales/ko/localization.json';
import pt from './locales/pt/localization.json';
import ru from './locales/ru/localization.json';
import th from './locales/th/localization.json';
import tr from './locales/tr/localization.json';
import zh from './locales/zh/localization.json';
import uk from './locales/uk/localization.json';
import fr from './locales/fr/localization.json';


i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: navigator.language || navigator.userLanguage,
    resources: {
        en: {
            translation: en
        },
        de: {
            translation: de
        },
        es: {
            translation: es
        },
        it: {
            translation: it
        },
        ja: {
            translation: ja
        },
        ko: {
            translation: ko
        },
        pt: {
            translation: pt
        },
        ru: {
            translation: ru
        },
        th: {
            translation: th
        },
        tr: {
            translation: tr
        },
        uk: {
            translation: uk
        },
        zh: {
            translation: zh
        },
        fr: {
            translation: fr
        },
    },
    interpolation: {
        escapeValue: false
    },

    wait: true
});


export default i18n;

