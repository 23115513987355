import React from 'react';
import style from './Location.style';
import { concatClasses } from 'helper';
import { isMobileOnly } from 'react-device-detect';


const TryBlock = () => (
    <div className={concatClasses(style.TryBlock, style.infoCard)}>
        {isMobileOnly ? (<>
            <hr/>
            <img className={style.TryBlock__img} src='./images/try-img.png' alt='' />
            
        </>) : <p className={style.infoCard__name}>Try GeoZilla</p>}
       
        <ul className={style.infoCard__list}>
            <li className={style.infoCard__item}>Track and share location privately</li>
            <li className={style.infoCard__item}>Get car crash alerts and driver coaching</li>
            <li className={style.infoCard__item}>Add a GPS Tracker device to your family map</li>
        </ul>
        <a className={concatClasses(style.TryBlock__btn, style.infoCard__btn)} href='https://premium.geozilla.com/?yearly'>Start Free Trial</a>
    </div >
);

export default TryBlock;