import React, {useEffect, useCallback} from 'react';
import {connect} from 'store';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps';
import debounce from 'lodash.debounce';
import { isMobileOnly } from 'react-device-detect';


// import mapStyle from './mapStyle';


const circleStyle = {
    strokeColor: 'transparent',
    fillColor: '#00BFA5',
    fillOpacity: '0.3'
};


const GoogleMapWrapper = withScriptjs(withGoogleMap(props => {

    const {
        state: {
            location: {
                accuracy,
                lat,
                lng,
            }
        },
        actions: {
            locationActions: {
                setGoogleAddressAction
            }
        }
    } = props;

    const currentLocation = { lat: parseFloat(lat), lng: parseFloat(lng) };
    
    const getGeoCode = () => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: {lat, lng} }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    setGoogleAddressAction(results[0].formatted_address);
                } else {
                    console.log('No results found');
                }
            } else {
                console.log('Geocoder failed due to: ' + status);
            }
        });
    };

    const delayedRequest = useCallback(debounce(getGeoCode, 1000), [lat, lng]);

    useEffect(() => {
        delayedRequest();

        return delayedRequest.cancel;
        
    }, [lat, lng, delayedRequest]);

    const modePosition = isMobileOnly ? window.google.maps.ControlPosition.TOP_CENTER : window.google.maps.ControlPosition.LEFT_BOTTOM;
    const zoomPosition = isMobileOnly ? window.google.maps.ControlPosition.RIGHT_CENTER : window.google.maps.ControlPosition.RIGHT_BOTTOM;

    return (
        <GoogleMap
            className='googleMap'
            defaultCenter={currentLocation}
            center={currentLocation}
            defaultZoom={17}
            defaultOptions={{
                fullscreenControl: false,
                streetViewControl: false,
                zoomControlOptions: {
                    position: zoomPosition
                },
                mapTypeControlOptions: {
                    mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
                    position: modePosition,
                }
            }}
        >

            <Marker
                icon={'./images/default-avatar.png'}
                position={currentLocation}
                animation={window.google.maps.Animation.DROP}
                visible={true}
            />
        
            <Circle
                radius={accuracy}
                center={currentLocation}
                options={circleStyle}
            />
        </GoogleMap>);

}
));

export default connect(GoogleMapWrapper);