import { createAction } from 'store';
import fetch from 'fetch';
import { toParam, requestAuth } from 'helper';
const REACT_API_HOST = process.env.REACT_API_HOST2 || '';
const REACT_API_HOST2 = process.env.REACT_API_HOST3 || '';


const sendAuthRequest = async params => {
    const { urlParams, token, sandbox } = params;
    const api = sandbox ? '/auth/authorize-sandbox' : '/auth/authorize-web';
    const url = REACT_API_HOST2 + api + toParam(urlParams);
    const config = requestAuth(token);
    const res = await fetch.get(url, config.headers);    
    return res;
};

const loginSoc = async params => {
    return fetch.get(REACT_API_HOST + '/authorize_social_via_access_token' + toParam(params));
};

const getUserInfo = async token => {
    const url = REACT_API_HOST + '/users/me';
    const config = requestAuth(token);
    const {entity: { geozilla_user_id }} = await fetch.get(url, config.headers);

    return geozilla_user_id;
};

const sendSignupRequest = ({captchaToken, email}) => {
    return fetch.post(REACT_API_HOST + '/signup?g-recaptcha-response=' + captchaToken, { registration_source: 'email', name: email, email: email });
};

const sendLoginRequest = ({ email, userPassword }) => {
    return fetch.post(REACT_API_HOST + '/login', { registration_source: 'email', email: email, password: userPassword });
};

const sendResetRequest = email => {
    return fetch.post(REACT_API_HOST + '/restore_password', { email });
};



export const SET_PARAMS = 'setParams';
export const LOGIN_SOC = 'loginSoc';
export const SOC_GET_INFO = 'getUserInfo';
export const SET_EMAIL = 'setEmail';
export const SET_PASS = 'setUserPassword';
export const VALIDATE_EMAIL = 'validateEmail';
export const REGISTER_EMAIL = 'registerEmail';
export const LOGIN_EMAIL = 'loginEmail';
export const RESET_PASSWORD = 'resetPassword';
export const SET_PARTNER_PARAMS = 'setPartnerParams';
export const SEND_AUTH_REQUEST = 'sendAuthRequest';
export const SET_CAPTCHA_TOKEN = 'setCaptchaToken';

const setParamsAction = createAction(SET_PARAMS);
const loginSocAction = createAction(LOGIN_SOC, loginSoc);
const getUserInfoAction = createAction(SOC_GET_INFO, getUserInfo);
const setEmailAction = createAction(SET_EMAIL);
const setUserPasswordAction = createAction(SET_PASS);
const registerEmailAction = createAction(REGISTER_EMAIL, sendSignupRequest);
const loginUserEmailAction = createAction(LOGIN_EMAIL, sendLoginRequest);
const resetPasswordAction = createAction(RESET_PASSWORD, sendResetRequest);
const setPartnerParamsAction = createAction(SET_PARTNER_PARAMS);
const sendAuthRequestAction = createAction(SEND_AUTH_REQUEST, sendAuthRequest);
const setCaptchaTokenAction = createAction(SET_CAPTCHA_TOKEN);

export default {
    setParamsAction,
    loginSocAction,
    getUserInfoAction,
    setEmailAction,
    registerEmailAction,
    setUserPasswordAction,
    loginUserEmailAction,
    resetPasswordAction,
    setPartnerParamsAction,
    sendAuthRequestAction,
    setCaptchaTokenAction
};