import React from 'react';
import style from './Coupon.style';


const CouponUkraine = (props) => (
    <div className={style.SpecialCoupon}>
        <div className={style.SpecialCoupon__contentBlock}>
            <p className={style.SpecialCoupon__stand_with_ukrane}>We stand with Ukraine</p>
            <h1 className={style.SpecialCoupon__title}>Залишайтеся на зв'язку з&nbsp;близькими</h1>
            <ul className={style.SpecialCoupon__list}>
                <li className={style.SpecialCoupon__item}>
                    Трансляція переміщень ваших близьких
                </li>
                <li className={style.SpecialCoupon__item}>
                    SOS кнопка з гео сповіщенням близьких
                </li>
                <li className={style.SpecialCoupon__item}>
                    Історія переміщень та остання відома локація
                </li>
            </ul>

            <b className={style.SpecialCoupon__codeTitle}>Ваш код преміум доступу на рік</b>
            <input onChange={props.onChange} value={props.value} className={style.SpecialCoupon__input} type="text" placeholder="Промокод" />
            <button onClick={props.onClick} className={style.SpecialCoupon__btn}>Активувати Преміум</button>
            <p className={style.SpecialCoupon__note}>Наша команда щиро з вами!</p>
            <img className={style.SpecialCoupon__icon} src='./images/heartsUa.svg' alt='Наша команда щиро з вами!'/>
            <p className={style.SpecialCoupon__cheersUkraine}>Слава Україні!</p>
        </div>
        <div className={style.SpecialCoupon__background}>
            <div className={style.SpecialCoupon__background_img_ua}/>
            <p className={style.SpecialCoupon__background_title}>We stand with Ukraine</p>
        </div>
    </div>
);

export default CouponUkraine;