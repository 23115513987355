import React, {useEffect} from 'react';
import {connect} from 'store';
import PasswordUI from '../PasswordUI/PasswordUI';
import {queryParecer} from 'helper';


const ResetPassword = props => {

    const {
        location: {search},
        history: {push},
        actions: {
            resetActions: {
                setURLParamsAction,
                sendResetRequestAction
            },
        },
        state: {
            password: {
                password,
            },
            reset: {
                urlHash,
                success,
            }
        }
    } = props;


    useEffect(() => {
        const query = queryParecer(search);
        query && setURLParamsAction(query);

    }, []);


    useEffect(() => {
        if (success) {
            push('/confirm-success');
        }

    }, [success]);

    const data = {
        password: password.value,
        hash: urlHash,
    };


    return (
        <>
            <PasswordUI
                apiAction={sendResetRequestAction}
                data={data}
            />
        </>
    );
};

export default connect(ResetPassword);


