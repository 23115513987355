import {
    PASSWORD,
    CONFIRMED_PASSWORD,
    SHOW_PASSWORD,
    SHOW_CONFIRMED_PASSWORD,
} from './actions';

const password = (state, value) => {

    return {
        ...state,
        password: {
            ...state.password,
            value,
        },
    };
};

const showPassword = (state, value) => {
    const { password: { type } } = state;
    if (type === value) {
        return;
    }
    return {
        ...state,
        password: {
            ...state.password,
            type: value
        }

    };
};

const confirmedPassword = (state, value) => {
    return {
        ...state,
        confirmedPassword: {
            ...state.confirmedPassword,
            value,
        },
    };
};

const showConfirmedPassword = (state, value) => {
    const { confirmedPassword: { type } } = state;
    if (type === value) {
        return;
    }
    return {
        ...state,
        confirmedPassword: {
            ...state.confirmedPassword,
            type: value
        }

    };
};

const handler = {
    [PASSWORD]: password,
    [SHOW_PASSWORD]: showPassword,
    [CONFIRMED_PASSWORD]: confirmedPassword,
    [SHOW_CONFIRMED_PASSWORD]: showConfirmedPassword,
};

const DEFAULT_STATE = {
    password: {
        type: 'password',
        value: '',
    },
    confirmedPassword: {
        type: 'password',
        value: '',
    },
};

export default { handler, DEFAULT_STATE };