import { createAction } from 'store';
import fetch from 'fetch';
const REACT_API_HOST = process.env.REACT_API_HOST || '';


const sendCoupon = (id) => {
    return fetch.post(REACT_API_HOST + '/payment-service/affiliate-coupon/check', { coupon: id });
};

export const COUPON = 'aplyCoupon';
export const SET_COUPON = 'setCoupon';
export const SET_SOURCE = 'setSource';

const applyCouponAction = createAction(COUPON, sendCoupon);
const setCouponAction = createAction(SET_COUPON);
const setSourceAction = createAction(SET_SOURCE);

export default {
    applyCouponAction,
    setCouponAction,
    setSourceAction
};


