import React, {useEffect} from 'react';
import QRCode from 'qrcode.react';
import {connect} from 'store';
import style from './Coupon.style.scss';
import {addStyle} from 'helper';

const Qrcode = (props) => {
    const {
        state: {
            coupon: {
                link,
            }
        },
        history: {
            push,
        }
    } = props;

    useEffect(() => {
        if(!link) {
            push('/spa');
        }
    }, []);

    return (
        <div className="container">
            <div className={style.coupon__wrapper}>
                <h1 className={style.coupon__heading}>Your Premium is ready!</h1>
                <p className={addStyle(style.coupon__text, style.coupon__text_desk, true)}>Scan the QR code with your
                    camera and enjoy GeoZilla!</p>
                <p className={addStyle(style.coupon__text, style.coupon__text_mob, true)}>All is set! <br/> Click the
                    button below to proceed</p>
                <div className={style.coupon__qr_container}>
                    <div className={style.coupon__qr}>
                        <QRCode value={link} size={200}/>
                    </div>
                    <p>Or open this link from your phone</p>
                    <a href={link}>{link}</a>
                </div>
                <a href={link} className={addStyle('btn_green', style.coupon__download_btn, true)}>GO TO THE APP</a>
            </div>
        </div>

    );
};

export default connect(Qrcode);