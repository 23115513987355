import { createAction } from 'store';


export const PASSWORD = 'setPassword';
export const CONFIRMED_PASSWORD = 'confirmPassword';
export const SHOW_PASSWORD = 'showPassword';
export const SHOW_CONFIRMED_PASSWORD = 'showConfirmedPassword';

const setPasswordAction = createAction(PASSWORD);
const setConfirmedPasswordAction = createAction(CONFIRMED_PASSWORD);
const showPasswordAction = createAction(SHOW_PASSWORD);
const showConfirmedPasswordAction = createAction(SHOW_CONFIRMED_PASSWORD);

export default {
    setPasswordAction,
    setConfirmedPasswordAction,
    showPasswordAction,
    showConfirmedPasswordAction,
};