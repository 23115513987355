import React from 'react';
import style from './Login.style';
import {connect} from 'store';

const LINK_APPLE = process.env.REACT_APP_DEEP_LINK_APPLE;
const LINK_GOOGLE = process.env.REACT_APP_DEEP_LINK_GOOGLE;


const newEmailFlow = (t) => (
    <div>
        <p className={style.Login_subTitle}>{t('whats_next')}</p>
        <ol className={style.Login_list}>
            <li>{t('please_confirm_email')}</li>
            <li>{t('set_password')}</li>
            <li>{t('donwload_and_login')}</li>
        </ol>
    </div>
);

const LoginSuccess = props => {

    const {
        guideLink,
        t,
        state: {
            login: {
                newUser,
            }
        },
    } = props;


    return (
        <div className={style.Login_container}>
            <img className={style.Login_logo} src='./images/logo-svg.svg' alt=''/>
            <p className={style.Login_title}>{t('awesome_all_set')}</p>

            {newUser && newEmailFlow(t)}


            {!newUser && <p className={style.Login_text}>{t('use_same_account')}</p>}

            <div className={style.Login_buttons}>
                <a href={LINK_APPLE} className={style.Login_storeBtn}>
                    <img src='./images/apple.png' alt=''/>
                </a>
                <a href={LINK_GOOGLE} className={style.Login_storeBtn}>
                    <img src='./images/google.png' alt=''/>
                </a>
            </div>

            {guideLink && (
                <div className={style.GuideNote} onClick={() => window.open(guideLink)}>
                    <p className={style.GuideNote__title}>{t('how_it_works')}</p>
                    <p className={style.GuideNote__link}>{t('quick_start_guide')}</p>
                </div>

            )}
        </div>
    );
};


export default connect(LoginSuccess);
