import { VALIDATE_EMAIL } from './actions';

const applyValidateEmail = (state, value) => {
    return {
        ...state,
        openAppLink: value.openAppLink,
        queryData: {
            userId: value.userId,
            code: value.code,
        },
        isConfirmed: true, // remove in keys of right flow
    };
};

const handler = {
    [VALIDATE_EMAIL]: applyValidateEmail,
};

const DEFAULT_STATE = {
    isConfirmed: false,
    openAppLink: '',
    queryData: null,
    userEmail: null,
};

export default { handler, DEFAULT_STATE };