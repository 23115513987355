// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes Spinner-style__spinLoader--2pzUY{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.Spinner-style__loader--1pDgu{width:100vw;height:100vh;background-color:#ffffffd6;border-radius:4px;position:fixed;top:0;left:0;z-index:1100;display:flex;flex-direction:column;justify-content:center;align-content:center;align-items:center}.Spinner-style__loader__spinner--2_QvK{height:100px;width:100px;border-radius:50%;background-color:transparent;border:3px solid #23CBA7;border-right-color:transparent;animation:Spinner-style__spinLoader--2pzUY 2s linear infinite}.Spinner-style__loader__loc--18h1c{width:80%;max-width:500px;margin:0 auto 40px;display:flex;flex-direction:column;align-items:center}.Spinner-style__loader__img--bYcqN{width:100%}.Spinner-style__loader__text--2uHMM{font-family:Avenir-Medium;font-size:24px;line-height:18px;text-align:center;letter-spacing:-0.138667px;color:#323232}@media screen and (min-width: 768px){.Spinner-style__loader__text--2uHMM{font-size:40px;line-height:18px}}\n", ""]);
// Exports
exports.locals = {
	"loader": "Spinner-style__loader--1pDgu",
	"loader__spinner": "Spinner-style__loader__spinner--2_QvK",
	"spinLoader": "Spinner-style__spinLoader--2pzUY",
	"loader__loc": "Spinner-style__loader__loc--18h1c",
	"loader__img": "Spinner-style__loader__img--bYcqN",
	"loader__text": "Spinner-style__loader__text--2uHMM"
};
module.exports = exports;
