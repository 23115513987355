
const checkResponse = (res) => {
    const isUploadFileWithNoCors = res.type === 'opaque';
    const { ok } = res;

    if (ok || isUploadFileWithNoCors) {
        return { res };
    }

    const { status, statusText } = res;
    const err = status || statusText;
    return { res, err };
};

const toJson = async ({ res, err }) => {
    const data = await res.json();
    return {
        data,
        err,
    };
};

const checkErr = ({ data, err }) => {
    if (err) {
        const e = new Error(err);
        e.details = data.errors.messages;
        e.code = err;
        e.statusCode = data.request_id;
        throw e;
    }
    const {data: defaultData } = data;

    if (defaultData) {
        return defaultData;
    }
    return data;
};

const fetchData = async (url, method, body, headers) => {
    const defaultHeders = {
        'Content-Type': 'application/json'
    };
    const options = {
        method: method,
        headers: headers ? Object.assign(headers, defaultHeders) : defaultHeders
    };

    if (body) {
        Object.assign(options, { body: JSON.stringify(body) });
    }

    const res = await fetch(url, { ...options });
    const result = checkResponse(res);
    const result_1 = await toJson(result);
    return checkErr(result_1);
};

const instance = {
    get: async (url, headers = null) => await fetchData(url, 'GET', null, headers),
    post: async (url, body, headers = null) => await fetchData(url, 'POST', body, headers),
    patch: async (url, body, headers = null) => await fetchData(url, 'PATCH', body, headers),
    delete: async (url, headers = null) => await fetchData(url, 'DELETE', headers)
};

export default instance;