import { createAction } from 'store';
import fetch from 'fetch';
const REACT_API_HOST = process.env.REACT_API_HOST || '';


const validateEmail = (body) => {
    return fetch.post(REACT_API_HOST + '/frontend/confirm-email/validate', body);
};

export const VALIDATE_EMAIL = 'validateEmail';

const validateEmailAction = createAction(VALIDATE_EMAIL, validateEmail);

export default {
    validateEmailAction,
};