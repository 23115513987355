const addStateKey = (key, func) => (state, value) => {
    const newState = state[key];
    const updateState = func(newState, value);
    return {
        ...state,
        [key]: {
            ...state[key],
            ...updateState,
        }
    };
};

export const createAction = (nameAction, fn = null) => dispatch => (value) => {
    if (!fn) {
        return dispatch({ type: nameAction, payload: value });
    }

    dispatch({ type: 'showSpinner', payload: true });
    return fn(value)
        .then(data => dispatch({ type: nameAction, payload: data }))
        .catch(err => dispatch({ type: 'setError', payload: err }))
        .then(() => dispatch({ type: 'showSpinner', payload: false }));
};

const handlerActions = (state, { type, payload }, handlers) => {
    const caller = handlers[type];

    if (caller) {
        return caller(state, payload);
    }
    return state;
};

export const combaineReducers = (reducers) => {
    const destructoreReducer = Object.entries(reducers);
    const sortingReducerData = destructoreReducer.reduce((acc, [name, { handler, DEFAULT_STATE }]) => {
        const handlers = Object.entries(handler);
        const modifyHandlers = handlers.reduce((akk, [key, func]) => ({
            ...akk,
            [key]: addStateKey(name, func)
        }), {});

        return {
            handlers: {
                ...acc.handlers,
                ...modifyHandlers,
            },
            state: {
                ...acc.state,
                [name]: DEFAULT_STATE,
            }
        };
    }, {});

    return {
        reducer: (state, actions) => handlerActions(state, actions, sortingReducerData.handlers),
        defautlState: sortingReducerData.state
    };
};

export const actionDispatcher = (actions, dispatch) => {
    const distructureActions = Object.entries(actions);
    const allActions = distructureActions.reduce((acc, [nameComponent, funcs]) => {
        const actionModule = Object.entries(funcs);
        const toAllActions = actionModule.reduce((akk, [key, fn]) => ({
            ...akk,
            [key]: fn(dispatch)
        }), {});
        return {
            ...acc,
            [nameComponent]: toAllActions,
        };
    }, {});
    return allActions;
};
