import React, { useEffect } from 'react';
import style from './Location.style';
import Lottie from 'react-lottie';
import clockAnimation from './clock-animation.json';
import { connect } from 'store';
import { concatClasses, getCurrentPosition, startTimer, addStyle, isLocationExpired, convertTime } from 'helper';
import LoadingCircle from './LoadingCircle';
import { isMobileOnly } from 'react-device-detect';




const notAvailable = (
    <p className={style.LocationInfo__notAvail}>Not available</p>
);

const LocationInfo = (props) => {

    const { 
        state: {
            location: {
                name,
                lat,
                lng,
                browserLat,
                browserLng,
                formattedAddress,
                startTime,
                sharingDuration,
                sharingOn,
                timestamp,
                isLocationExp,
                timer: {
                    hours,
                    mins,
                }
            }
        },
        actions: {
            locationActions: {
                getBrowserLocationAction,
                setTimerDataAction,
                setSharingStatusAction,
                setExpirationStatusAction,
            }
        }
    } = props;

    const defaultAnimationOptions = {
        loop: true,
        autoplay: true,
        animationData: clockAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    useEffect(() => {
        getCurrentPosition(getBrowserLocationAction);
    }, []);
    
    useEffect(() => {
        if (startTime && sharingDuration) {
            startTimer(startTime, sharingDuration, setTimerDataAction, setSharingStatusAction);
        } 
    }, [startTime, sharingDuration]);

    useEffect(() => {
        if (timestamp) {
            setExpirationStatusAction(isLocationExpired(timestamp));

            const timeout = setTimeout(() => {
                setExpirationStatusAction(isLocationExpired(timestamp));
            }, 60000);
    
            return () => {
                clearTimeout(timeout);
            };
        }
        
    }, [timestamp]);

    const onClickHandler = () => {

        if (browserLat && browserLng) {
            window.open('https://maps.google.com/maps?saddr=' + browserLat + ',' + browserLng + '&daddr=' + lat + ',' + lng);
        }

        else {
            window.open('https://maps.google.com/maps?daddr=' + lat + ',' + lng);
        }
    };

    const timeToDisplay = (hours, mins) => { 

        if (0 < mins < 1) {
            return (
                <p className={style.infoCard__time}>Available for <span>less than a minute</span></p>
            ); 
        }

        if (sharingDuration >= 3600) {

            if (hours <= 0) {
                return (
                    <p className={style.infoCard__time}>Available for <span>{mins}</span> minutes</p>
                ); 
            }
            return (
                <p className={addStyle(style.infoCard__time, style.disabled, !sharingOn)}>Available for <span>{hours}</span> hours and <span>{mins}</span> minutes</p>
            ); 
        }

        if (mins === 1) {
            return (
                <p className={style.infoCard__time}>Available for <span>{mins}</span> minute</p>
            );
        }

        return (
            <p className={style.infoCard__time}>Available for <span>{mins}</span> minutes</p>
        );
    };

    let blockCls = isMobileOnly ? style.infoCard__mob : concatClasses(style.LocationInfo, style.infoCard);
    
       
    return (
        <div className={blockCls}>
            {name && formattedAddress ?
                (<>
                    <p className={addStyle(style.infoCard__name, style.disabled, !sharingOn)}>{`${name}’s location`}</p>
                    <p className={addStyle(style.infoCard__address, style.disabled, !sharingOn)}>{formattedAddress}</p>
                    {isLocationExp && <p className={addStyle(style.LocationInfo__last, style.disabled, !sharingOn)}>{`Last updated at ${convertTime(timestamp)}`}</p>}

                    {sharingOn ? <div className={style.LocationInfo__timer}>
                        <Lottie 
                            options={defaultAnimationOptions}
                            height={25}
                            width={25}
                        />
                        {timeToDisplay(hours, mins)}
                    </div> : notAvailable}

                    <button className={concatClasses(style.LocationInfo__btn, style.infoCard__btn)} onClick={onClickHandler}>Get Directions</button>
                </>) : <LoadingCircle />}
        </div>
    );
   
};


export default connect(LocationInfo);