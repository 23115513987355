import passwordActions from '../components/PasswordUI/Reducer/actions';
import setPassActions from '../components/SetUserPass/Reducer/actions';
import spinnerActions from '../components/Spinner/Reducer/actions';
import couponActions from '../components/Coupon/Reducer/actions';
import errorActions from '../components/Error/Reducer/actions';
import emailActions from '../components/ConfirmEmail/Reducer/actions';
import resetActions from '../components/ResetPassword/Reducer/actions';
import locationActions from '../components/Location/Reducer/actions';
import loginActions from '../components/Login/Reducer/actions';
import modalActions from '../components/ModalPopup/Reducer/actions';

export default {
    passwordActions,
    spinnerActions,
    couponActions,
    errorActions,
    emailActions,
    locationActions,
    loginActions,
    modalActions,
    resetActions,
    setPassActions
};