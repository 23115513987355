import React, { useEffect } from 'react';
import {connect} from 'store';
import Map from './Map';
import LocationInfo from './LocationInfo';
import Expired from './Expired';
import TryBlock from './TryBlock';
import SlidingPane from '../SlidingPane/SlidingPane';
import styles from './Location.style';
import firebase from 'firebase/app';
import 'firebase/database';
import { getFireBaseData, subscribetToFirebase, queryParecer, unSubscribeFromFirebase } from 'helper';
import { isMobileOnly } from 'react-device-detect';




const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID 
};



firebase.initializeApp(firebaseConfig);


const Location = (props) => {

    const {
        history: {
            push
        },
        location: {
            search,
        },
        state: {
            location: {
                userId,
                lat,
                lng,
                sharingOn,
            }
        },
        actions: {
            locationActions: {
                setLocationAction,
                setUserDataAction,
            },
            spinnerActions: {
                setSpinnerAction
            }
        }
    } = props;

    useEffect(() => {
        const preventBehavior = e => e.preventDefault();

        document.addEventListener('touchmove', preventBehavior, {passive: false});
    }, []);


    useEffect(() => {
        const data = queryParecer(search);
        getFireBaseData(firebase, data.id, setUserDataAction, ()=>push('/'));
    }, []);

    useEffect(() => {
        userId && subscribetToFirebase(firebase, userId, setLocationAction, setSpinnerAction);
    }, [userId]);

    useEffect(() => {
        if (!sharingOn) {
            unSubscribeFromFirebase(firebase, userId);
        }
           
    }, [sharingOn]);


    const mapBlock = (
        <div className={styles.map}>
            {(lat && lng) && (<Map />)}

            {isMobileOnly ? (
                <SlidingPane>
                    <LocationInfo />
                    <TryBlock/>
                </SlidingPane>) : (<div className={styles.cardsBlock}>
                <LocationInfo />
                <TryBlock />
            </div>)
            }
        </div>);


    return (
        <div className='wrapper'>
            {userId && sharingOn === false ? <Expired /> : mapBlock}
        </div>
    );
};


export default connect(Location);