import React from 'react';
import { connect } from 'store';
import Login from '../Login/Login';
import DemoSuccess from './DemoSuccess';




const DemoLogin = props => {

    const {
        location: {
            search
        },
        state: {
            login: {
                token,
            }
        },
    } = props;
    

    if (token) {
        return <DemoSuccess sandbox={true}/>;
    }

    return <Login params={search}/>;

};

export default connect(DemoLogin);

