const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const script = `
var GoogleAuth;
function init() {
    gapi.load('auth2', function(){
        window.GoogleAuth = gapi.auth2.init({
            client_id: '${REACT_APP_GOOGLE_CLIENT_ID}',
            scope: 'openid profile email',
            prompt: 'select_account',
        })
    })
}
`;
const src = 'https://apis.google.com/js/platform.js?onload=init';

export default {
    script,
    src,
};