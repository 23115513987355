import React, {useEffect} from 'react';
import {connect} from 'store';
import {queryParecer} from 'helper';
import PasswordUI from '../PasswordUI/PasswordUI';


const SetUserPass = (props) => {

    const {
        location: {search},
        history: {push},
        state: {
            password: {
                password
            },
            main: {
                success
            },
            email: {
                queryData,
            }
        },
        actions: {
            setPassActions: {
                installPasswordAction,
            },
        }
    } = props;

    useEffect(() => {
        if (success) {
            push('/confirm-success');
        }

    }, [success]);

    const data = {password: password.value};


    if (queryData) {
        Object.assign(data, {...queryData});
    } else {
        const query = queryParecer(search);
        Object.assign(data, {...query});
    }

    return <PasswordUI
        apiAction={installPasswordAction}
        data={data}
    />;
};

export default connect(SetUserPass);