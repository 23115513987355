import React, {useEffect} from 'react';
import style from './Login.style';
import { connect } from 'store';
import EmailLogin from './EmailLogin';
import Social from './Social';
import ModalPopup from '../ModalPopup/ModalPopup';
import SigninForm from '../SigninForm/SigninForm';
import { queryParecer, verifyGoogleToken } from 'helper';
import Alert from '../Alert/Alert';
import useLoadScripts from '../../scripts/ScriptProvider';
import ReCaptcha from '../../scripts/ReCaptcha';


const Login = props => {

    const {
        params,
        t,
        state: {
            login: {
                email,
                captchaToken
            },
            error: {
                data
            },
        },
        actions: {
            loginActions: {
                registerEmailAction,
                setPartnerParamsAction,
                setCaptchaTokenAction
            },
            modalActions: {
                showModalAction
            }
        },
    } = props;


    useLoadScripts({ src: ReCaptcha, check: 'recaptcha' });

    useEffect(() => {
        setTimeout(() => verifyGoogleToken(setCaptchaTokenAction), 1000);
    }, []);


    useEffect(() => {

        if (params) {
            const urlParams = queryParecer(params);

            urlParams && setPartnerParamsAction(urlParams);
        }

    }, []);

    

    const onProceedClick = () => {
        registerEmailAction({captchaToken, email});
    };

    const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

    const alertText = <>{t('already_have_account')} <button className={style.Login_btnText} onClick={() => showModalAction(true)}>{t('lo_in')}</button></>;


    return (
        <div className={style.Login_container}>
            <img className={style.Login_logo} src='./images/logo-svg.svg' alt='' />
            <p className={style.Login_title}>{t('sign_up')}</p>
            <p className={style.Login_subTitle}>{t('connect_device')}</p>
            <EmailLogin />
           
            {data && <Alert type='alert-info' text={alertText} />}
           
            <p className={style.Login_or}>{t('or')}</p>
            <Social/>
            <button className={style.Login_btn} onClick={onProceedClick} disabled={!isValid}>{t('proceed')}</button>
            <button className={style.Login_haveAccount} onClick={()=>showModalAction(true)}>{t('have_an_account')}</button>
            <p className={style.Login_note}>{t('by_creating_account')} <a href='https://geozilla.com/terms-of-use/'>{t('terms_of_use')}</a> {t('and')} <a href='https://geozilla.com/privacy-policy/'>{t('privacy_policy')}</a></p>
            <ModalPopup>
                <SigninForm/>
            </ModalPopup>
        </div>
    );
};


export default connect(Login);