import React, {useEffect} from 'react';
import {connect} from 'store';
import {queryParecer, callTimeOut, wraper} from 'helper';
import style from './ConfirmEmail.style';
import { useTranslation } from 'react-i18next';



const ConfirmEmail = (props) => {

    const {
        t,
        location: { search },
        history: { push },
        actions: {
            emailActions: {
                validateEmailAction
            }
        },
        state: {
            email: {
                isConfirmed,
            }
        }
    } = props;

    useEffect(() => {
        const data = queryParecer(search);
        validateEmailAction(data);
    }, []);

    useEffect(() => {
        if(isConfirmed) {
            callTimeOut(wraper(push, '/confirm-password'), 3);
        }
    }, [isConfirmed]);

    return (

        <div className="container">
            <p className={style.text}> {isConfirmed ? t('confirming_email') : t('email_not_confirmed')}</p>
        </div>
    );
};

export default connect(ConfirmEmail);