import { createAction } from 'store';


export const SHOW_MODAL = 'showModal';

const showModalAction = createAction(SHOW_MODAL);


export default {
    showModalAction,
};