import React from 'react';
import style from './Header.style';
import { connect } from 'store';
import Navigation from '../Navigation/Navigation';




const Header = ({ loc }) => {
    let headerStyle = style.header;
    let headerLogo = './images/geo-logo.svg';

    if (loc === 'password') {
        headerStyle = style.header__pass;
        headerLogo = './images/logo.svg';
    }

    if (loc === 'success') {
        headerLogo = './images/success-logo.svg';
        headerStyle = style.header__pass;
    }
    
    if (loc === 'location') {
        headerLogo = './images/geo-logo.png';
        headerStyle = style.header__location;
    }

    if (loc === 'demo') {
        headerStyle = style.header__demo;
    }

   
    return (
        <div className={headerStyle}>
            <a href={'https://www.geozilla.com/'}>
                <img src={headerLogo} alt={'GeoZilla Logo'}/>
            </a>
            {loc === 'location' ? <Navigation/> : null}
        </div>
    );
};

export default connect(Header);