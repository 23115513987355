// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModalPopup-style__modalPopup--1bf_i{width:auto !important;max-width:100%;height:auto !important;margin:0 auto;box-sizing:border-box;background:#FFFFFF;border-radius:16px !important;border:none !important;animation:ModalPopup-style__fadeIn--oblcR 0.7s both ease-in-out}@media screen and (max-width: 374px){.ModalPopup-style__modalPopup--1bf_i{width:290px}}.ModalPopup-style__modalPopup__body--QzhSI{display:flex;flex-direction:column;align-items:center;justify-content:space-between;text-align:center}.ModalPopup-style__modalPopup__close_btn--3mFB-{width:30px;height:40px;margin-left:auto;position:absolute;right:20px;cursor:pointer;padding:10px;z-index:100}.ModalPopup-style__modalPopup__close_btn--3mFB-:focus{outline:none}.ModalPopup-style__modalPopup__backdrop--PhUfL{background-color:rgba(50,50,50,0.4) !important}@keyframes ModalPopup-style__fadeIn--oblcR{0%{opacity:0;transform:translatey(0)}100%{opacity:1;transform:translatey(-40px)}}\n", ""]);
// Exports
exports.locals = {
	"modalPopup": "ModalPopup-style__modalPopup--1bf_i",
	"fadeIn": "ModalPopup-style__fadeIn--oblcR",
	"modalPopup__body": "ModalPopup-style__modalPopup__body--QzhSI",
	"modalPopup__close_btn": "ModalPopup-style__modalPopup__close_btn--3mFB-",
	"modalPopup__backdrop": "ModalPopup-style__modalPopup__backdrop--PhUfL"
};
module.exports = exports;
