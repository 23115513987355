import { SEND_RESET_REQUEST, SET_URL_PARAMS } from './actions';

const setParams = (state, value) => {

    return {
        ...state,
        urlHash: value.hash,
        portal: value.portal
    };
};

const setSuccess = (state) => {
    return {
        ...state,
        success: true
    };
};



const handler = {
    [SET_URL_PARAMS]: setParams,
    [SEND_RESET_REQUEST]: setSuccess,
};

const DEFAULT_STATE = {
    userEmail: null,
    isUserValid: null,
    success: null
};

export default { handler, DEFAULT_STATE };