import { SHOW_MODAL } from './actions';

const setModal = (state, value) => {
    return {
        ...state,
        show: value,
    };
};

const handler = {
    [SHOW_MODAL]: setModal,
};

const DEFAULT_STATE = {
    show: false,
};

export default { handler, DEFAULT_STATE };