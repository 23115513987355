// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Error-style__error__noPopup--84GOc{display:none}.Error-style__error__noPopup--84GOc *{display:none}.Error-style__error__popup--3U3p6{position:relative;z-index:1100;width:340px;padding:15px;box-sizing:border-box;border-radius:4px;background-color:rgba(243,54,78,0.4);margin:0 auto;z-index:200}@media screen and (min-width: 768px){.Error-style__error__popup--3U3p6{position:absolute;top:100px;right:30px}}.Error-style__error__text--3Xtua{margin:0;text-align:center;font-family:Avenir-Medium;font-style:normal;font-weight:500;font-size:16px;color:#F3364E}\n", ""]);
// Exports
exports.locals = {
	"error__noPopup": "Error-style__error__noPopup--84GOc",
	"error__popup": "Error-style__error__popup--3U3p6",
	"error__text": "Error-style__error__text--3Xtua"
};
module.exports = exports;
