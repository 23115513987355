import React from 'react';
import ReactDOM from 'react-dom';
import {concatClasses} from 'helper';
import style from './ModalPopup.style';
import { connect } from 'store';


const modalRoot = document.getElementById('modal');

const ModalPopup = props => {
    
    const {
        children,
        state: {
            modal: {
                show
            }
        },
        actions: {
            modalActions: {
                showModalAction
            }
        }
    } = props;

    if(show){
        return ReactDOM.createPortal(
            <div className={concatClasses('modal-backdrop', style.modalPopup__backdrop)}>
                <div className="modal-dialog modal-dialog-centered" tabIndex="-1" role="dialog">
                    <div className={concatClasses('modal-content', style.modalPopup)}>
                        <button type="button" onClick={()=>showModalAction(false)} className={concatClasses('close', style.modalPopup__close_btn)} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className={concatClasses('modal-body', style.modalPopup__body)}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            ,
            modalRoot
        );
    }
    return null;
};



export default connect(ModalPopup);