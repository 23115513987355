import React from 'react';
import {connect} from 'store';
import style from './PasswordUI.style';
import { useTranslation } from 'react-i18next';


const Success = (props) => {
    const { t, } = useTranslation('translation', { useSuspense: false });


    return (
        <div className="container">
            <div className={style.main__wrapper}>
                <p className={style.main__text}>{t('confirm_email_success_message')}</p>
            </div>
        </div>
    );
};

export default connect(Success);