import { SET_SPINNER } from './actions';

const setSpinner = (state, value) => {
    return {
        ...state,
        show: value,
    };
};

const handler = {
    [SET_SPINNER]: setSpinner,
};

const DEFAULT_STATE = {
    show: false,
};

export default { handler, DEFAULT_STATE };