import React from 'react';

export const Facebook = () => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z" fill="#3B5998" />
        <path d="M21.8995 18.1851H18.7769V29.625H14.0458V18.1851H11.7957V14.1646H14.0458V11.5629C14.0458 9.7024 14.9295 6.78906 18.819 6.78906L22.3235 6.80372V10.7063H19.7807C19.3637 10.7063 18.7772 10.9147 18.7772 11.8022V14.1683H22.3129L21.8995 18.1851Z" fill="white" />
    </svg>
);

export const Google = () => (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M34.0001 17.3904C34.0001 16.2348 33.9045 15.0729 33.7006 13.9361H17.3411V20.4825H26.7094C26.3206 22.5938 25.0715 24.4615 23.2425 25.6484V29.896H28.8316C32.1137 26.9351 34.0001 22.5626 34.0001 17.3904Z" fill="#4285F4" />
            <path d="M17.3411 34C22.0189 34 25.9638 32.4946 28.838 29.896L23.2488 25.6484C21.6938 26.6853 19.6863 27.2725 17.3474 27.2725C12.8226 27.2725 8.98606 24.2804 7.60949 20.2576H1.84192V24.6364C4.78625 30.377 10.7832 34 17.3411 34Z" fill="#34A853" />
            <path d="M7.60313 20.2576C6.87661 18.1463 6.87661 15.86 7.60313 13.7487V9.36984H1.84193C-0.618047 14.1734 -0.618047 19.8328 1.84193 24.6364L7.60313 20.2576Z" fill="#FBBC04" />
            <path d="M17.3411 6.72754C19.8138 6.69006 22.2037 7.60206 23.9945 9.27614L28.9463 4.42256C25.8108 1.53665 21.6492 -0.0499712 17.3411 1.20094e-06C10.7832 1.20094e-06 4.78625 3.623 1.84192 9.36983L7.60311 13.7487C8.97331 9.71964 12.8162 6.72754 17.3411 6.72754Z" fill="#EA4335" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="34" height="34" fill="white" />
            </clipPath>
        </defs>
    </svg>

);


