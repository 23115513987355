import { SET_COUPON, COUPON, SET_SOURCE } from './actions';

const setCouponValue = (state, value) => {
    return {
        ...state,
        value,
    };
};

const setSourceValue = (state, source) => {
    return {
        ...state,
        source,
    };
};

const aplyCoupon = (state, {deep_link}) => {
    return {
        ...state,
        link: deep_link
    };
};

const handler = {
    [SET_COUPON]: setCouponValue,
    [COUPON]: aplyCoupon,
    [SET_SOURCE]: setSourceValue,
};

const DEFAULT_STATE = {
    value: '',
    link: '',
    source: ''
};

export default {handler, DEFAULT_STATE};