import React from 'react';
import {connect} from 'store';
import ShowIcon from '../SetUserPass/ShowIcon';
import style from './PasswordUI.style.scss';
import {targetEvent, wraper, addStyle} from 'helper';



const PasswordUI = (props) => {

    const {
        t,
        actions: {
            passwordActions: {
                setPasswordAction,
                setConfirmedPasswordAction,
                showPasswordAction,
                showConfirmedPasswordAction,
            },
        },
        state: {
            password: {
                password,
                confirmedPassword,
            }
        }
    } = props;

    const isEqual = password.value.length === confirmedPassword.value.length && password.value === confirmedPassword.value;
    const isShort = password.value.length >= 8;
    const isValid = isShort && isEqual;
    const isEmpty = password.value;


    return (
        <div className="container">
            <div className={style.main__wrapper}>
                <p className={style.main__text}>{props.t('confirm_email_info_text')}</p>

                <div className={style.main__form}>
                    <input onChange={targetEvent(setPasswordAction)} value={password.value} id="pass" type={password.type} name="pass" minLength={8} className={addStyle(style.main__input, style.invalid, isEmpty && !isValid)} placeholder={t('password_field_placeholder')}/>
                    <button
                        className={style.main__show_btn}
                        onMouseDown={wraper(showPasswordAction, 'text')}
                        onMouseLeave={wraper(showPasswordAction, 'password')}
                        tabIndex="-1"
                    >
                        {(password.value) && <ShowIcon/>}

                    </button>
                    <input onChange={targetEvent(setConfirmedPasswordAction)} value={confirmedPassword.value} id="confirmed" type={confirmedPassword.type} name="confirmed" className={addStyle(style.main__input, style.invalid, isEmpty && !isValid)} placeholder={t('frontend_confirm_password_input_placeholder')}/>
                    <button
                        className={addStyle(style.main__show_btn, style.main__show_btn_confirm, true)}
                        onMouseDown={wraper(showConfirmedPasswordAction, 'text')}
                        onMouseLeave={wraper(showConfirmedPasswordAction, 'password')}
                        tabIndex="-1"
                    >
                        {(confirmedPassword.value) && <ShowIcon/>}
                    </button>
                    {(isShort && !isEqual) &&
                    <p className={style.error__text}>{props.t('passwords_fields_not_match')}</p>}
                    {(isEmpty && !isShort) &&
                    <p className={style.error__text}>{props.t('password_too_short_error')}</p>}
                    <div className={style.main__btn_wrapper}>
                        <button className={addStyle('btn_green', 'btn_green_disabled', !isEmpty || !isValid)} disabled={!isEmpty || !isValid}
                            onClick={wraper(props.apiAction, props.data)}
                        >{t('confirm_button_text')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default connect(PasswordUI);