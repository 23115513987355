import React, {useState} from 'react';
import style from './Navigation.style';
import { addStyle } from 'helper';


const LINKS = [
    {
        name: 'Locator App',
        link: 'https://findmy.geozilla.com/'
    }, {
        name: 'Tracking Device',
        link: 'https://gps-tracker.geozilla.com/'
    }, {
        name: 'Driver Protection',
        link: 'https://geozilla.com/driving/'
    }, {
        name: 'Pricing',
        link: 'https://join.geozilla.com/'
    }, {
        name: ' Support',
        link: 'https://geozilla.com/contact/'
    }
];

const renderNavList = (name, href) => {
    return (
        <li className={style.Navigation__item} key={name}>
            <a href={href}>{name}</a>
        </li>
    );
};


const Navigation = () => {

    const [nav, setNav] = useState(false);

    return (
        <nav className={style.Navigation}>
            <button className={style.Navigation__toggle} aria-label="Toggle navigation" onClick={() => setNav(!nav)}>
                <div className={addStyle(style.Navigation__bar1, style.Navigation__bar1_change, nav)}></div>
                <div className={addStyle(style.Navigation__bar2, style.Navigation__bar2_change, nav)}></div>
                <div className={addStyle(style.Navigation__bar3, style.Navigation__bar3_change, nav)}></div>
            </button>
            <ul className={addStyle(style.Navigation__list, style.Navigation__list_opened, nav)}>
                {LINKS.map(i => renderNavList(i.name, i.link))}
            </ul>
        </nav>
        
    );
};


export default Navigation;

