import moment from 'moment';
const {REACT_APP_CAPTCHA_KEY} = process.env;



export const targetEvent = (fn) => ({
    target: {
        value
    }

}) => fn(value);

export const wraper = (fn, ...args) => () => {
    fn(...args);
};

export const addStyle = (style, styleError, bol) => {
    if (bol) {
        return style + ' ' + styleError;
    }
    return style;
};

export const toParam = (obj) => {
    const keys = Object.keys(obj);

    const str = keys.reduce((acc, item) => {
        return acc + item + '=' + obj[item] + '&';
    }, '?');

    return str;
};

export const requestAuth = (data) => {
    const config = {};
    if (typeof data === 'string') {
        Object.assign(config, {
            headers: {
                Authorization: `Bearer ${data}`
            }
        });
        return config;
    }
    const {
        token,
        ...params
    } = data;
    Object.assign(config, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params,
    });
    return config;
};

export const concatClasses = (...args) => {
    const style = args.join(' ');

    return style;
};

export const queryParecer = (str) => {
    const cutStr = str.slice(1);
    const coupleStr = cutStr.split('&').map(item => item.split('='));
    const obj = coupleStr.reduce((acc, [key, value]) => {
        return {
            ...acc,
            [key]: value
        };
    }, {});
    return obj;
};


export const callTimeOut = (fn, sec) => {
    const time = sec * 1000;
    setTimeout(fn, time);
};

export const getFireBaseData = (database, params, reducer, errorHandler) => {
    const userData = database.database().ref('/sharing/' + params);

    userData.once('value').then(snapshot => {
        if (snapshot.val()) {
            reducer(snapshot.val());
        } else {
            errorHandler();
        }
    });
};


export const subscribetToFirebase = (database, params, reducer, errorHandler) => {
    const locationData = database.database().ref('locations/' + params);

    locationData.on('value', snapshot => {
        if (snapshot.val()) {
            reducer(snapshot.val());
            errorHandler(false);
        } else {
            errorHandler(true);
        }
    });
};

export const unSubscribeFromFirebase = (database, params) => {
    const locationData = database.database().ref('locations/' + params);

    locationData.off();
};

// export const createMarker = (img, setImg) => {

//     const MARKER_BG = './images/icon-bg.png';

//     const avatar = new Image(88, 88);
//     const background = new Image(100, 120);


//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');

//     avatar.src = img;
//     avatar.setAttribute('crossorigin', 'anonymous');
//     background.src = MARKER_BG;

//     const width = 120;
//     const height = 120;

//     window.onload = () => {
//         ctx.clearRect(0, 0, 100, 120);
//         ctx.drawImage(background, 90, 15);
//         ctx.arc(152, 74, 45, 0, 2 * Math.PI);
//         ctx.clip();
//         ctx.drawImage(avatar, 100, 28, width, height);
//         setImg(canvas.toDataURL());
//     };
// };

export const checkLocation = (start, duration) => {
    const currentTime = Math.floor(new Date().getTime());

    const durationInMilSec = duration * 1000;

    const endTime = start + durationInMilSec;

    if (currentTime <= endTime) {
        return true;
    } else {
        return false;
    }

};


export const isLocationExpired = (timestamp) => {
    const currentTime = Math.floor(new Date().getTime());
    const diff = currentTime - (timestamp * 1000);
    let isExp = diff > 60000 ? true : false;

    return isExp;
};

export const getCurrentPosition = (action) => navigator.geolocation.getCurrentPosition((position) => {
    action({
        browserLat: position.coords.latitude,
        browserLng: position.coords.longitude
    });
});

export const startTimer = (sharingStartTime, duration, setTimerData, setSharingStatus) => {

    const timer = setInterval(() => {

        const currentTime = new Date().getTime();
        const timePast = currentTime - sharingStartTime;
        const diff = duration * 1000 - timePast;

        const countDownDate = currentTime + diff;

        const distance = countDownDate - currentTime;

        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        if (distance < 0) {
            clearInterval(timer);
            setSharingStatus(false);
            setTimerData({
                hours: 0,
                mins: 0
            });
            return;
        }


        setTimerData({
            hours,
            mins,
        });
    }, 1000);

    return timer;
};


export const convertTime = timestamp => {
    return moment(timestamp * 1000).format('h:mm a');
};


export const verifyGoogleToken = action => {

    const captchaKey = REACT_APP_CAPTCHA_KEY.toString();

    window.grecaptcha.ready(() => {
        window.grecaptcha
            .execute(captchaKey, {
                action: 'homepage'
            })
            .then(token => {
                action(token);
            });
    });
};
