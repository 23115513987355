import { SET_LOCATION, SET_USER_DATA, GET_BROWSER_LOCATION, SET_EXPIRATION_STATUS, SET_GOOGLE_ADDRESS, SET_TIMER_DATA, SET_SHARING_STATUS } from './actions';
import { checkLocation} from 'helper';

const setLocationData = (state, payload) => {
    const {
        a,
        g,
        i,
        t
    } = payload;

    const {
        startTime,
        sharingDuration
    } = state;

    if (checkLocation(startTime, sharingDuration)) {
        return {
            ...state,
            accuracy: a,
            lat: i,
            lng: g,
            timestamp: t,
            sharingOn: true
        };
    }

    return {
        ...state,
        accuracy: null,
        lat: null,
        lng: null,
        timestamp: null,
        sharingOn: false
    };
};

const setUserData = (state, payload) => {
    const {
        duration,
        image_url,
        name,
        start_time,
        user_id
    } = payload;

    const DEFAULT_AVATAR = './images/default-avatar.png';

    const avatarImg = image_url ? image_url : DEFAULT_AVATAR;


    return {
        ...state,
        sharingDuration: duration,
        avatar: avatarImg,
        name: name,
        startTime: start_time,
        userId: user_id,
    };
};

const getBrowserLocation = (state, payload) => {
    return {
        ...state,
        ...payload
    };
};

// const setCanvas = (state, payload) => {

//     return {
//         ...state,
//         canvasAvatar: payload
//     };
// };

const setFormattedAddress = (state, payload) => {
    return {
        ...state,
        formattedAddress: payload
    };
};

const setTime = (state, payload) => {

    const {
        hours,
        mins,
    } = payload;
    return {
        ...state,
        timer: {
            hours,
            mins,
        }
    };
};

const setSharing = (state, payload) => {

    return {
        ...state,
        sharingOn: payload
    };
};

const setExpired = (state, payload) => {

    return {
        ...state,
        isLocationExp: payload
    };
};


const DEFAULT_STATE = {
    sharingDuration: null,
    // avatar: './images/default-avatar.png',
    // canvasAvatar: '',
    name: '',
    startTime: null,
    userId: null,
    accuracy: null,
    lat: 1234578,
    lng: 124567,
    timestamp: null, //location generation time
    sharingOn: null,
    browserLat: null,
    browserLng: null,
    formattedAddress: '',
    isLocationExp: null,
    timer: {
        hours: 12,
        mins: 0
    }
};

const handler = {
    [SET_LOCATION]: setLocationData,
    [SET_USER_DATA]: setUserData,
    [GET_BROWSER_LOCATION]: getBrowserLocation,
    [SET_GOOGLE_ADDRESS]: setFormattedAddress,
    [SET_TIMER_DATA]: setTime,
    [SET_SHARING_STATUS]: setSharing,
    [SET_EXPIRATION_STATUS]: setExpired,
};

export default {
    handler,
    DEFAULT_STATE
};