import { SET_ERROR } from './actions';

const setError = (state, value) => {

    return {
        ...state,
        data: value
    };
};

const handler = {
    [SET_ERROR]: setError,
};

const DEFAULT_STATE = {
    data: null
};

export default {handler, DEFAULT_STATE};