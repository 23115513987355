import React, {useEffect} from 'react';
import { connect } from 'store';
import useLoadScripts from '../../scripts/ScriptProvider';
import GGL from '../../scripts/GGL';
import FB from '../../scripts/FB';
import {Google, Facebook} from './Icons';
import style from './Login.style';


const Social = props => {

    const {
        state: {
            login: {
                access_token,
                driver,
                token
            }
        },
        actions: {
            loginActions: {
                setParamsAction,
                loginSocAction,
                getUserInfoAction
            }
        },
    } = props;


    useLoadScripts({ script: FB, check: 'FB' });
    useLoadScripts({ script: GGL.script, src: GGL.src, check: 'GoogleAuth' });

    const loginGGL = setParams => () => {
        window.GoogleAuth.signIn()
            .then(gglRes => {
                const res = gglRes.getAuthResponse();
                const params = {
                    access_token: res.access_token,
                    driver: 'google'
                };
                setParams(params);
            });
    };


    const loginFB = setParams => () => {
        window.FB.login((res) => {
            if (res.status === 'connected') {
                const params = {
                    access_token: res.authResponse.accessToken,
                    driver: 'facebook'
                };
                setParams(params);
            }

        }, { scope: 'email' });
    };

    useEffect(() => {
        access_token && loginSocAction({driver, access_token});
    }, [access_token]);

    useEffect(() => {
        token && getUserInfoAction(token);
    }, [token]);


    return (
        <div className={style.SocContainer}>
            <button className={style.SocBtn} onClick={loginGGL(setParamsAction)} aria-label='Sign in with Google'>
                <Google/>
            </button>
            <button className={style.SocBtn} onClick={loginFB(setParamsAction)} aria-label='Sign in with Facebook'>
                <Facebook/>
            </button>
        </div>
    );
};

export default connect(Social);