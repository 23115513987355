import { useEffect, useState } from 'react';

const useScript = (s) => {
    useEffect(() => {
        if(!s) {
            return;
        }
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.innerHTML = s;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [s]);
};

const useSrcScript = (src) => {
    useEffect(() => {
        if(!src) {
            return;
        }
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = src;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [src]);
};

const useLoadScripts = (props) => {
    const {
        script,
        src,
        check
    } = props;

    const [load, setLoad] = useState(false);
    useSrcScript(src);
    useScript(script);

    useEffect(() => {
        const interval = setInterval(() => {
            if(window[check]) {
                setLoad(true);
            }
        }, 1000);
        if(load){
            clearInterval(interval);
        }

    }, [load]);

    return load;
};


export default useLoadScripts;