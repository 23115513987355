import React from 'react';
import style from './SlidingPane.style';
import { motion } from 'framer-motion';
import {connect} from 'store';






const SlidingPane = props => {


    let topLimit = window.innerHeight / 1.75;
    let bottomLimit = window.innerHeight / 3.9;

    if (window.innerHeight < 630) {
        topLimit = window.innerHeight / 1.75;
        bottomLimit = window.innerHeight / 3.9;
        
        if (window.innerHeight < 600) {
            topLimit = window.innerHeight / 1.2;
            bottomLimit = window.innerHeight / 4.5;
        }

        if (window.innerHeight < 300) {
            topLimit = window.innerHeight * 5;
            bottomLimit = window.innerHeight / 10;
        }
    }




    return (
        
        <motion.div
            drag="y"
            dragConstraints={{ top: -topLimit, bottom: bottomLimit}}
            className={style.SlidingPane}
            dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}

        >
            {props.children}
            
        </motion.div>
       
    );

};

export default connect(SlidingPane);