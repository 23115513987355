import React, {useEffect} from 'react';
import {connect} from 'store';
import {targetEvent, wraper, queryParecer} from 'helper';
import style from './Coupon.style';
import CouponBelarus from './CouponBelarus';
import CouponUkraine from './CouponUkraine';

const PromoCode = (props) => {
    const {
        location: { search },
        state: {
            coupon: {
                value,
                link,
                source
            }
        },
        actions: {
            couponActions: {
                setSourceAction,
                applyCouponAction,
                setCouponAction,
            }
        },
        history: {
            push,
        }
    } = props;

    useEffect(() => {
        const data = queryParecer(search);
        const decodedCoupon = decodeURIComponent(data.coupon);
        const decodedSource = decodeURIComponent(data.source);
        
        if (data.coupon) {
            setCouponAction(decodedCoupon);
            setSourceAction(decodedSource);
        }
       
    }, []);

    useEffect(() => {
        if (link) {
            push('/qrcode');
        }
    }, [link]);


    return (
        <>
            { 
                (value === 'ЖывеБеларусь')
                    ? <CouponBelarus value={value} onChange={targetEvent(setCouponAction)} onClick={wraper(applyCouponAction, value)} />
                    : (value === 'СлаваУкраїні')
                        ? <CouponUkraine value={value} onChange={targetEvent(setCouponAction)} onClick={wraper(applyCouponAction, value)} />
                        : (<div className="container">
                            <div className={style.coupon__wrapper}>
                                {source === 'appsumo' && <img className={style.Yearly__img} src='./images/gzappsumo.png' alt=''/>}
                                <h1 className={style.coupon__heading}>{source === 'appsumo' ? 'Stay connected with GeoZilla' : 'Coupon activation'}</h1>
                                {source === 'appsumo' && (
                                    <>
                                        <ul className={style.Yearly__list}>
                                            <li className={style.Yearly__item}>Share real-time location with family, friends, or colleagues</li>
                                            <li className={style.Yearly__item}>Crash detection and geo alerts in case of an emergency</li>
                                            <li className={style.Yearly__item}>Check travel history and last known location</li>
                                        </ul>
                                    </>
                                )}
                                <p className={style.coupon__text}>{source === 'appsumo' ? 'Your code for a year of Premium access' : 'Enter the coupon code below to get your premium'}</p>
                                <input onChange={targetEvent(setCouponAction)} value={value} className={style.coupon__input} type="text" placeholder="Coupon code" />
                                <button onClick={wraper(applyCouponAction, value)} className="btn_green">Activate Premium</button>
                            </div>
                        </div>)
            }
        </>
        
    );
};

export default connect(PromoCode);