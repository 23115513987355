import React from 'react';
import { Route, BrowserRouter, Switch, Router } from 'react-router-dom';
import { Store } from 'store';
import './App.style';
import Spinner from './components/Spinner/Spinner';
import SetUserPass from './components/SetUserPass/SetUserPass';
import Coupon from './components/Coupon/Coupon';
import Qrcode from './components/Coupon/Qrcode';
import Error from './components/Error/Error';
import Header from './components/Header/Header';
import NotFound from './components/NotFound/NotFound';
import Success from './components/PasswordUI/Success';
import ConfirmEmail from './components/ConfirmEmail/ConfirmEmail';
import ResetPassword from './components/ResetPassword/ResetPassword';
import DemoLogin from './components/Demo/DemoLogin';
import IotLogin from './components/IotLogin/IotLogin';
import Location from './components/Location/Location';
import PurchaseSuccess from './components/Login/PurchaseSuccess';
import './i18next';

const customHeader = (loc) => () => (<Header loc={loc}/>);
const customSpinner = (loc) => () => (<Spinner loc={loc} />);
const customError = (loc) => () => (<Error loc={loc} />);


const HEADER = [
    {path: ['/location'], Comp: customHeader('location')},
    {path: ['/confirm-password', '/confirm-email', '/reset-password'], Comp: customHeader('password')},
    {path: '/confirm-success', Comp: customHeader('success')},
    {path: ['/demo-login', '/iot-login', '/purchase-success'], Comp: customHeader('demo')},
    {path: ['*', '/apply-coupon', '/qrcode',], Comp: customHeader()},
];

const SPINNER = [
    { path: ['/location'], Comp: customSpinner('location') },
    { path: ['*'], Comp: customSpinner() },
];

const ERROR = [
    { path: ['/demo-login', '/iot-login'], Comp: customError('login') },
    { path: ['*'], Comp: customError() }
];

const App = () => {

    return (
        <Store>
            
            <BrowserRouter>
                <Switch>
                    {
                        HEADER.map(({path, Comp}) => (<Route key={path} path={path} exact component={Comp} />))
                    }
                </Switch>
                <Switch>
                    {
                        SPINNER.map(({path, Comp}) => (<Route key={path} path={path} exact component={Comp} />))
                    }
                </Switch>
                <Switch>
                    {
                        ERROR.map(({path, Comp}) => (<Route key={path} path={path} exact component={Comp} />))
                    }
                </Switch>
                <Switch>
                    <Route path="/apply-coupon" exact component={Coupon} />
                    <Route path="/location" exact component={Location} />
                    <Route path="/qrcode" exact component={Qrcode} />
                    <Route path='/confirm-email' exact component={ConfirmEmail}/>
                    <Route path="/confirm-password" exact component={SetUserPass} />
                    <Route path="/confirm-success" exact component={Success} />
                    <Route path="/reset-password" exact component={ResetPassword} />
                    <Route path="/reset-success" exact component={Success} />
                    <Route path="/demo-login" exact component={DemoLogin} />
                    <Route path="/iot-login" exact component={IotLogin} />
                    <Route path="/purchase-success" exact component={PurchaseSuccess} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </BrowserRouter>
        </Store>
    );
};

export default App;