// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header-style__header--3JncB{max-width:1440px;margin:0 auto;padding:20px}.Header-style__header--3JncB img{width:130px;height:60px}@media screen and (min-width: 768px){.Header-style__header--3JncB img{width:140px;height:50px}}.Header-style__header__demo--w8BpF{display:none}.Header-style__header__pass--2r1T5{max-width:1440px;margin:0 auto;text-align:center;padding:20px}.Header-style__header__pass--2r1T5 img{width:85px;height:106.99px}.Header-style__header__location--29vRj{position:relative;display:flex;flex-direction:row;justify-content:space-between;padding:20px}@media screen and (min-width: 1140px){.Header-style__header__location--29vRj{padding:17px 70px}}@media screen and (max-width: 1140px) and (orientation: landscape){.Header-style__header__location--29vRj{width:100%;position:absolute;z-index:1500}}@media screen and (max-width: 767px){.Header-style__header__location--29vRj{position:absolute;width:100%}}.Header-style__header__location--29vRj a{width:40px;position:relative;z-index:100}.Header-style__header__location--29vRj img{width:40px;height:auto}\n", ""]);
// Exports
exports.locals = {
	"header": "Header-style__header--3JncB",
	"header__demo": "Header-style__header__demo--w8BpF",
	"header__pass": "Header-style__header__pass--2r1T5",
	"header__location": "Header-style__header__location--29vRj"
};
module.exports = exports;
