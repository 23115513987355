import React from 'react';
import Login from '../Login/Login';
import { connect } from 'store';
import IotSuccess from './IotSuccess';



const IotLogin = props => {

    const {
        location: {
            search
        },
        state: {
            login: {
                userID,
            }
        }
    } = props;


    if (userID) {
        return <IotSuccess/>;
    }


    return <Login params={search}/>;

};

export default connect(IotLogin);
