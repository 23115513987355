import React, {useEffect, useState} from 'react';
import LoginSuccess from '../Login/LoginSuccess';
import {connect} from 'store';
import Alert from '../Alert/Alert';

const VENDORS_CLIENT_IDS = {
    'ripple': 'https://geozilla.com/guides/ripple_safety_device/',
};


const IotSuccess = props => {
    const [guideLink, setGuideLink] = useState('');

    const {
        t,
        state: {
            login: {
                status,
                urlParams,
                token,
                userID,
            }
        },
        actions: {
            loginActions: {
                sendAuthRequestAction
            }
        }

    } = props;

    useEffect(() => {

        if (urlParams.integration_type === 'direct_purchase') {
            const PARTNER_URL = `http://shop.minifinder.com?tracking=geozilla&product_id=${urlParams.vendor_id}`;

            document.location.href = `${PARTNER_URL}&user_id=${userID}`;
        } else {
            sendAuthRequestAction({urlParams, token});
        }
    }, [urlParams, userID]);

    useEffect(() => {
        const IDs = Object.keys(VENDORS_CLIENT_IDS);
        const vendor = IDs.find(i => urlParams.client_id.includes(i));
        vendor && setGuideLink(VENDORS_CLIENT_IDS[vendor]);
    }, [urlParams]);

    if (status) {
        return <LoginSuccess guideLink={guideLink}/>;
    }

    if (status === false) {
        return <Alert type='alert-danger' text={t('linking_error')}/>;
    }

    return null;
};

export default connect(IotSuccess);