import { createAction } from 'store';
import fetch from 'fetch';
const REACT_API_HOST4 = process.env.REACT_API_HOST4 || '';


const setPassword = (body) => {
    return fetch.post(REACT_API_HOST4 + '/ajax/reset-password.json', body);
};

export const SEND_RESET_REQUEST = 'sendResetRequest';
export const SET_URL_PARAMS = 'setURLParams';

const sendResetRequestAction = createAction(SEND_RESET_REQUEST, setPassword);
const setURLParamsAction = createAction(SET_URL_PARAMS);

export default {
    sendResetRequestAction,
    setURLParamsAction
};