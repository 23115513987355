import main from '../components/SetUserPass/Reducer/reducer';
import password from '../components/PasswordUI/Reducer/reducer';
import spinner from '../components/Spinner/Reducer/reducer';
import coupon from '../components/Coupon/Reducer/reducer';
import error from '../components/Error/Reducer/reducer';
import email from '../components/ConfirmEmail/Reducer/reducer';
import reset from '../components/ResetPassword/Reducer/reducer';
import location from '../components/Location/Reducer/reducer';
import login from '../components/Login/Reducer/reducer';
import modal from '../components/ModalPopup/Reducer/reducer';

export default {
    main,
    spinner,
    coupon,
    error,
    email,
    reset,
    location,
    login,
    modal,
    password
};