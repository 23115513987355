import { createAction } from 'store';

export const SET_LOCATION = 'setLocation';
export const SET_USER_DATA = 'setUserData';
export const GET_BROWSER_LOCATION = 'getBrowserLocation';
export const SET_CANVAS_AVATAR = 'setCanvasAvatar';
export const SET_GOOGLE_ADDRESS = 'setGoogleAddress';
export const SET_TIMER_DATA = 'setTimerData';
export const SET_SHARING_STATUS = 'setSharingStatus';
export const SET_EXPIRATION_STATUS = 'setExpirationStatus';

const setLocationAction = createAction(SET_LOCATION);
const setUserDataAction = createAction(SET_USER_DATA);
const getBrowserLocationAction = createAction(GET_BROWSER_LOCATION);
const setCanvasAvatarAction = createAction(SET_CANVAS_AVATAR);
const setGoogleAddressAction = createAction(SET_GOOGLE_ADDRESS);
const setTimerDataAction = createAction(SET_TIMER_DATA);
const setSharingStatusAction = createAction(SET_SHARING_STATUS);
const setExpirationStatusAction = createAction(SET_EXPIRATION_STATUS);


export default {
    setLocationAction,
    setUserDataAction,
    getBrowserLocationAction,
    setCanvasAvatarAction,
    setGoogleAddressAction,
    setTimerDataAction,
    setSharingStatusAction,
    setExpirationStatusAction
};