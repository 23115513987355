import React from 'react';



const Alert = ({ type, text }) => {
    
    const cls = `alert ${type}`;
    return (
        <div className={cls} role="alert">{text}</div>
    );
};


export default Alert;