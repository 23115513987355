import React from 'react';
import style from './Coupon.style';


const CouponBelarus = (props) => (
    <div className={style.SpecialCoupon}>
        <div className={style.SpecialCoupon__contentBlock}>
            <h1 className={style.SpecialCoupon__title}>Оставайтесь на связи с близкими</h1>
            <ul className={style.SpecialCoupon__list}>
                <li className={style.SpecialCoupon__item}>
                    Трансляция перемещений ваших близких
                </li>
                <li className={style.SpecialCoupon__item}>
                    SOS кнопка с гео оповещением близких
                </li>
                <li className={style.SpecialCoupon__item}>
                    История перемещений и последняя известная локация
                </li>
            </ul>

            <b className={style.SpecialCoupon__codeTitle}>Ваш код на год премиум доступа</b>
            <input onChange={props.onChange} value={props.value} className={style.SpecialCoupon__input} type="text" placeholder="Промокод" />
            <button onClick={props.onClick} className={style.SpecialCoupon__btn}>Активировать Премиум</button>
            <p className={style.SpecialCoupon__note}>Наша команда всей душою с вами!</p>
            <img className={style.SpecialCoupon__icon} src='./images/heartsBy.svg' alt='Наша команда всей душою с вами!'/>
        </div>

        <div className={style.SpecialCoupon__background}>
            <div className={style.SpecialCoupon__background_img_by}/>
            {/*<img src='./images/city-desk.png' alt='Independence Square, Minsk'/>*/}
        </div>
    </div>
);

export default CouponBelarus;