import React from 'react';
import style from './Location.style';


const Expired = () => (
    <div className={style.Expired}>
        <img className={style.Expired__img} src='./images/expired.svg' alt='Location is no longer available' />
        <h1 className={style.Expired__title}>Sorry</h1>
        <p className={style.Expired__text}>The location is no longer available</p>
    </div>
);

export default Expired;