import React, {useState, useEffect} from 'react';
import { connect } from 'store';
import style from './SigninForm.style';
import {addStyle} from 'helper';
import ShowIcon from './ShowIcon';
import Social from '../Login/Social';

const SigninForm = props => {

    const [type, setType] = useState('password');

    const {
        t,
        state: {
            login: {
                email,
                userPassword,
                resetLinkSent,
                token
            },
            error: {
                data
            }
        },
        actions: {
            loginActions: {
                setEmailAction,
                setUserPasswordAction,
                loginUserEmailAction,
                resetPasswordAction,
                getUserInfoAction
            },
            errorActions: {
                setErrorAction
            }
        },
    } = props;

    useEffect(()=>{
        setErrorAction(null);
    }, []);

    useEffect(() => {
        token && getUserInfoAction(token);
    }, [token]);

    const onEmailChange = e => setEmailAction(e.target.value);
    const onPassChange = e => setUserPasswordAction(e.target.value);
    
    return (
        <div className={style.signinForm}>
            <p className={style.signinForm__title}>{t('log_in_to')}</p>
            <input className={addStyle(style.signinForm__email, style.Error, data)} type='email' placeholder={t('email')} value={email} onChange={onEmailChange} autoComplete="username"/>

            <input
                type={type} className={addStyle(style.signinForm__input, style.Error, data)}
                placeholder={t('password')}
                onChange={onPassChange}
                value={userPassword}
                autoComplete='password'
                id="pass"
            />

            {data && <p className={style.ErrorText}>{t('ivalid_data_try_again')}</p>}

            <p className={style.signinForm_or}>{t('or')}</p>

            <Social />
            
            <button onMouseDown={()=>setType('text')} onMouseLeave={()=>setType('password')} className={style.signinForm__showBtn}>
                {userPassword ? <ShowIcon/> : null}
            </button>
            <button disabled={!userPassword} className={style.signinForm_btn} onClick={() => loginUserEmailAction({ email, userPassword })}>{t('lo_in')}</button>
            {!resetLinkSent ? (<button onClick={()=>resetPasswordAction(email)} className={style.signinForm__reset_btn}>{t('forgot_password')}</button>
            ) : (<p className={style.signinForm__reset_text}>{t('we_have_sent_reset_link')}</p>)}
        </div>
    );
};


export default connect(SigninForm);