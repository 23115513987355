import {
    SET_PARAMS,
    LOGIN_SOC,
    SET_EMAIL,
    SET_PASS,
    RESET_PASSWORD,
    LOGIN_EMAIL,
    REGISTER_EMAIL,
    SET_PARTNER_PARAMS,
    SEND_AUTH_REQUEST,
    SET_CAPTCHA_TOKEN, SOC_GET_INFO
} from './actions';


const setLoginData = (state, payload) => {
    return {
        ...state,
        login: true,
        driver: payload.driver,
        access_token: payload.access_token,
    };
};

const setNewEmailLoginData = (state, payload) => {

    return {
        ...state,
        login: true,
        driver: 'email',
        newUser: true,
        token: payload.access_token,
        userID: payload.entity.geozilla_user_id
    };
};

const setExistingLoginData = (state, payload) => {
    return {
        ...state,
        login: true,
        driver: 'email',
        newUser: false,
        token: payload.access_token,
    };
};

const setToken = (state, payload) => {

    return {
        ...state,
        token: payload.access_token,
    };
};

const setUserID = (state, payload) => {

    return {
        ...state,
        userID: payload,
    };
};

const setEmail = (state, value) => {
    return {
        ...state,
        email: value,
    };
};

const setPassword = (state, value) => {
    return {
        ...state,
        userPassword: value,
    };
};

const setReset = (state) => {
    return {
        ...state,
        resetLinkSent: true,
    };
};

const setAuthParams = (compState, payload) => {
    const {client_id, scope, redirect_uri, state, integration_type, partner_name, vendor_id} = payload;

    if (payload) {
        if (scope) {
            const parsedScope = scope.replace('+', ' ');

            return {
                ...compState,
                urlParams: {
                    client_id: client_id,
                    scope: parsedScope,
                    redirect_uri: redirect_uri,
                    state: state,
                    response_type: 'code',
                }
            };
        }

        if (integration_type && partner_name && vendor_id) {
            return {
                ...compState,
                urlParams: {
                    integration_type: integration_type,
                    partner: partner_name,
                    vendor_id: vendor_id
                }
            };
        }
    }
};

const setAuthResponse = (state, payload) => {

    if (payload.code) {
        return {
            ...state,
            code: payload.code,
            url: payload._redirect_url
        };
    }

    if (payload.redirect_code) {

        if (/(?!([45]0[0-9]))\d{3}/.test(payload.redirect_code)) {

            return {
                ...state,
                status: true,
            };
        }
    }

    return {
        ...state,
        status: false,
    };
};

const setCaptcha = (state, payload) => {
    return {
        ...state,
        captchaToken: payload,
    };
};


const handler = {
    [SET_PARAMS]: setLoginData,
    [LOGIN_SOC]: setToken,
    [SOC_GET_INFO]: setUserID,
    [SET_EMAIL]: setEmail,
    [REGISTER_EMAIL]: setNewEmailLoginData,
    [SET_PASS]: setPassword,
    [LOGIN_EMAIL]: setExistingLoginData,
    [RESET_PASSWORD]: setReset,
    [SET_PARTNER_PARAMS]: setAuthParams,
    [SEND_AUTH_REQUEST]: setAuthResponse,
    [SET_CAPTCHA_TOKEN]: setCaptcha,
};

const DEFAULT_STATE = {
    login: null,
    driver: null,
    access_token: null,
    token: null,
    userID: null,
    email: '',
    userPassword: '',
    newUser: null,
    urlParams: {
        client_id: null,
        scope: null,
        redirect_uri: null,
        response_type: 'code',
        state: null,
        integration_type: null,
        partner: null,
        vendor_id: null
    },
    code: null,
    status: null,
    captchaToken: null,

};

export default {handler, DEFAULT_STATE};