import { createAction } from 'store';
import fetch from 'fetch';
const REACT_API_HOST = process.env.REACT_API_HOST || '';


const installPassword = (body) => {
    return fetch.post(REACT_API_HOST + '/frontend/confirm-email/set-password', body);
};


export const INSTALL_PASSWORD = 'installPassword';

const installPasswordAction = createAction(INSTALL_PASSWORD, installPassword);

export default {
    installPasswordAction,
};