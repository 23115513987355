import React from 'react';
import GoogleMapWrapper from './GoogleMapWrapper';
import style from './Location.style';




const Map = (props) => {

    return (
        <GoogleMapWrapper
            googleMapURL={process.env.REACT_APP_GOOGLE_MAPS_API}
            loadingElement={<div style={{ width: '100vw', height: '100%' }} />}
            containerElement={<div className={style.googleMap} />}
            mapElement={<div style={{ width: '100vw', height: '100%' }} />}
        />
    );
};
    

export default Map;



