// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SlidingPane-style__SlidingPane--1a_GI{width:100vw;height:100vh;min-height:800px;background-color:#fff;box-shadow:0px 2px 16px rgba(0,0,0,0.12);border-radius:20px 20px 0 0;z-index:1600;position:absolute;top:calc(100vh - 330px);overflow:hidden;padding:30px 12px 0;box-sizing:border-box}.SlidingPane-style__SlidingPane--1a_GI::before{content:\"\";width:24px;height:4px;background-color:#E0E0E0;position:absolute;left:50%;top:12px;margin-left:-12px}@media screen and (orientation: landscape){.SlidingPane-style__SlidingPane--1a_GI{height:1200px;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between}}\n", ""]);
// Exports
exports.locals = {
	"SlidingPane": "SlidingPane-style__SlidingPane--1a_GI"
};
module.exports = exports;
