// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmEmail-style__text--1PYcW{padding:30px;font-family:Avenir-Medium;font-size:18px;line-height:28px;color:#323232;text-align:center}\n", ""]);
// Exports
exports.locals = {
	"text": "ConfirmEmail-style__text--1PYcW"
};
module.exports = exports;
