import React from 'react';
import style from './Login.style';
import { connect } from 'store';
import QRCode from 'qrcode.react';

const DEEPLINK = process.env.REACT_APP_LOGIN_DEEPLINK;

const PurchaseSuccess = ({t}) => {

    return (
        <div className={style.PurchaseSuccess}>
            <div className={style.PurchaseWebview}>
                <img className={style.Login_logo} src='./images/logo-svg.svg' alt=''/>
                <h1 className={style.Login_title}>{t('awesome_all_set')}</h1>

                <a href={DEEPLINK} className={style.PurchaseSuccess_btn}>{t('get_to_app')}</a>
                <div className={style.QRCode}>
                    <p>{t('scan_qr_code')}</p>
                    <div className={style.QRCode_container}>
                        <QRCode value={DEEPLINK} size={200}/>
                    </div>
                </div>

                <p className={style.PurchaseWebview_text}>{t('make_sure_to_log_in')}.</p>

            </div>
        </div>
    );
};


export default connect(PurchaseSuccess);