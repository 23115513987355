import React, {useEffect, useState} from 'react';
import LoginSuccess from '../Login/LoginSuccess';
import { connect } from 'store';
import Alert from '../Alert/Alert';


const VENDORS_CLIENT_IDS = {
    'ripple': 'https://geozilla.com/guides/ripple_safety_device/',
};

const DemoSuccess = props => {
    const [guideLink, setGuideLink] = useState('');


    const {
        sandbox,
        state: {
            login: {
                code,
                urlParams,
                token,
                url
            }
        },
        actions: {
            loginActions: {
                sendAuthRequestAction
            }
        }
    } = props;

    useEffect(() => {
        sendAuthRequestAction({ urlParams, token, sandbox });
    }, []);

    useEffect(() => {
        const IDs = Object.keys(VENDORS_CLIENT_IDS);
        const vendor = IDs.find(i => urlParams.client_id.includes(i));
        vendor && setGuideLink(VENDORS_CLIENT_IDS[vendor]);
    }, [urlParams]);

    
    const alertLink = <p>We have successfully linked the accounts and sent the auth code to the <a style={{color: '#155724', textDecoration: 'underline'}} href={url}>redirect_uri you provided:</a></p>;


    return (
        <>
            {code ? (<>
                <Alert type='alert-success' text={alertLink}/>
                <LoginSuccess guideLink={guideLink}/>
            </>) : <Alert type='alert-danger' text='The linking request failed. Please try again!' />}
        </>
    );
};

export default connect(DemoSuccess);