// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PasswordUI-style__main__wrapper--143XC{width:290px;margin:0 auto;padding-top:40px;text-align:center}.PasswordUI-style__main__logo--1BIkl{width:85px;height:106px}.PasswordUI-style__main__text--e2ozr{padding:0 30px;font-family:Avenir-Medium;font-size:18px;line-height:28px;color:#323232}.PasswordUI-style__main__text--e2ozr>span{color:#07c0b1}.PasswordUI-style__main__btn_wrapper--2cnMM{width:210px;margin:0 auto}.PasswordUI-style__main__form--1OXkF{position:relative;display:flex;width:290px;margin:40px auto;flex-direction:column}.PasswordUI-style__main__input--3NiaE{width:100%;height:50px;padding:14px 15px;border:1px solid #D8D8D8;box-sizing:border-box;border-radius:4px;outline:none;line-height:20px}.PasswordUI-style__main__input--3NiaE:first-of-type{margin-bottom:16px}.PasswordUI-style__main__input--3NiaE::-webkit-input-placeholder{font-family:Avenir-Light;font-size:16px;color:#707070}.PasswordUI-style__main__input--3NiaE:focus-within{border-color:#070707}.PasswordUI-style__main__btn--1ExT9:hover,.PasswordUI-style__main__btn--1ExT9:focus{background:radial-gradient(94.7% 132.14% at -19.63% -21.56%, #02F3B4 0%, #07B7A9 100%)}.PasswordUI-style__main__btn--1ExT9:active{background:linear-gradient(0deg, rgba(0,0,0,0.16), rgba(0,0,0,0.16)),radial-gradient(94.7% 132.14% at -19.63% -21.56%, #00FFBC 0%, #07BEB0 100%)}.PasswordUI-style__main__btn--1ExT9:disabled{background-color:#E0E0E0}.PasswordUI-style__main__show_btn--1-x79{position:absolute;top:11px;right:22px;width:30px;height:24px;border:none;background-color:transparent;outline:none}.PasswordUI-style__main__show_btn--1-x79 :hover path{fill:#00BFA5}.PasswordUI-style__main__show_btn_confirm--ScMZw{top:78px}.PasswordUI-style__invalid--1TAqY{border-color:#F3364E}.PasswordUI-style__error__text--3GgRi{margin:7px 0 0;padding-left:4px;text-align:left;font-family:Avenir-Medium;font-size:14px;color:#F3364E}.PasswordUI-style__error__popup--3aMaa{width:340px;background-color:#F3364E}\n", ""]);
// Exports
exports.locals = {
	"main__wrapper": "PasswordUI-style__main__wrapper--143XC",
	"main__logo": "PasswordUI-style__main__logo--1BIkl",
	"main__text": "PasswordUI-style__main__text--e2ozr",
	"main__btn_wrapper": "PasswordUI-style__main__btn_wrapper--2cnMM",
	"main__form": "PasswordUI-style__main__form--1OXkF",
	"main__input": "PasswordUI-style__main__input--3NiaE",
	"main__btn": "PasswordUI-style__main__btn--1ExT9",
	"main__show_btn": "PasswordUI-style__main__show_btn--1-x79",
	"main__show_btn_confirm": "PasswordUI-style__main__show_btn_confirm--ScMZw",
	"invalid": "PasswordUI-style__invalid--1TAqY",
	"error__text": "PasswordUI-style__error__text--3GgRi",
	"error__popup": "PasswordUI-style__error__popup--3aMaa"
};
module.exports = exports;
