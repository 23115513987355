import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import reducers from './reducers';
import actions from './actions';
import { combaineReducers, actionDispatcher } from './common';

export const Context = React.createContext();

const { reducer, defautlState } = combaineReducers(reducers);


const Store = (props) => {
    const [state, dispatch] = useReducer(reducer, defautlState);
    const { t, } = useTranslation('translation', { useSuspense: false });


    return (<Context.Provider
        value={{
            state,
            actions: actionDispatcher(actions, dispatch),
            t,
        }}>
        {
            props.children
        }
    </Context.Provider>);
};

export default Store;

