import {
    INSTALL_PASSWORD,
} from './actions';

const successOk = (state, value) => {
    const {success} = state;
    return {
        ...state,
        success: !success
    };
};

const handler = {
    [INSTALL_PASSWORD]: successOk,
};

const DEFAULT_STATE = {
    success: false,
};

export default { handler, DEFAULT_STATE };