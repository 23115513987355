// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotFound-style__notfound--2u0Qf{width:340px;margin:0 auto;text-align:center}@media screen and (min-width: 768px){.NotFound-style__notfound--2u0Qf{width:80%}}.NotFound-style__notfound__heading--cgdm1{position:relative;margin:20px 0;text-align:center;font-family:Avenir-Black;font-style:normal;font-weight:800;font-size:182px;line-height:150px;color:#00BFA5}.NotFound-style__notfound__img--jPj3R{width:374px;height:152px}@media screen and (max-width: 768px){.NotFound-style__notfound__img--jPj3R{width:80%}}.NotFound-style__notfound__text--2XBdl{text-align:center;font-family:Avenir-Medium;font-size:18px;line-height:28px;color:#323232}.NotFound-style__notfound__text--2XBdl>span{color:#07c0b1}\n", ""]);
// Exports
exports.locals = {
	"notfound": "NotFound-style__notfound--2u0Qf",
	"notfound__heading": "NotFound-style__notfound__heading--cgdm1",
	"notfound__img": "NotFound-style__notfound__img--jPj3R",
	"notfound__text": "NotFound-style__notfound__text--2XBdl"
};
module.exports = exports;
