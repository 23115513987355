import React from 'react';
import style from './Login.style';
import { connect } from 'store';



const EmailLogin = props => {

    const {
        t,
        state: {
            login: {
                email,
            }
        },
        actions: {
            loginActions: {
                setEmailAction,
            }
        },
    } = props;

    const onEmailChange = e => setEmailAction(e.target.value);

    return (
        <>
            <input className={style.Email} type='email' placeholder={t('email')} value={email} onChange={onEmailChange} autoComplete="username"/>
        </>
    );
};


export default connect(EmailLogin);