import React from 'react';
import { Context } from './context';

const connect = (Comp) => {
    const APP = (props) => {
        return (
            <Context.Consumer>
                {
                    value => <Comp {...value} {...props} />
                }
            </Context.Consumer>
        );
    };
    return APP;
};

export default connect;
