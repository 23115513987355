import React, {useEffect} from 'react';
import {connect} from 'store';
import style from './Error.style.scss';


const Error = (props) => {

    const { loc, state: { error: { data } }, actions: { errorActions: { setErrorAction } } } = props;
    let cls = style.error__popup;

    if (loc === 'login') {
        cls = style.error__noPopup;
    }
    

    useEffect(() => {
        if (data) {
            setTimeout(() => {setErrorAction(null);}, 3000);
        }
    }, [data]);

    if (!data) {
        return null;
    }
    
    return (
        <div className={cls}>
            <p className={style.error__text}>{props.t('server_problems_error')}</p>
        </div>
    );
};

export default connect(Error);