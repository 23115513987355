import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'store';
import style from './Spinner.style';


const spinnerRoot = document.getElementById('spinner');


const Spinner = (props) => {


    if(props.state.spinner.show){
        return ReactDOM.createPortal(
            <div className={style.loader}>
                {props.loc === 'location' && (
                    <div className={style.loader__loc}>
                        <img className={style.loader__img} src='./images/waiting.svg' alt='Waiting for location' />
                        <p className={style.loader__text}>Waiting for location</p>
                    </div>
                )}
                <div className={style.loader__spinner}> </div>
            </div>
            ,
            spinnerRoot
        );
    }
    return null;
};




export default connect(Spinner);